import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { offerPersistStorage } from './app/stores/offer/offer.store';
import { ordersPersistStorage } from './app/stores/orders/orders.store';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

const providers = [
  { provide: 'persistStorage', useValue: offerPersistStorage },
  { provide: 'persistStorage', useValue: ordersPersistStorage }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
