import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
  loggedIn
} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
const redirectLoggedInToHome = () => redirectLoggedInTo([]);


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'language-modal',
    loadChildren: () =>
      import('./pages/language-modal/language-modal.module').then(
        (m) => m.LanguageModalPageModule
      ),
  },
  {
    path: 'flights',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/flight-list/flight-list.module').then(
            (m) => m.FlightListPageModule
          ),
        pathMatch: 'full',
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/flight-offer-detail/flight-offer-detail.module').then(
            (m) => m.FlightOfferDetailPageModule
          ),
      },
    ],
  },
  {
    path: 'orders/:id/:phone',
    loadChildren: () =>
      import('./pages/flight-order/flight-order.module').then(
        (m) => m.FlightOrderPageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/private-policy/private-policy.module').then( m => m.PrivatePolicyPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'cookie-policy',
    loadChildren: () => import('./pages/cookie-policy/cookie-policy.module').then( m => m.CookiePolicyPageModule)
  },
  { path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
