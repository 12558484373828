import { ActionReducer } from "@ngrx/store";
import { LOGOUT } from "./system.action";
import { localStorageSync } from "ngrx-store-localstorage";

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        return reducer(action.type === LOGOUT ? undefined : state, action);
    };
}

export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: ['favoriteFlight', 'flightSearch', 'flightBooking', 'settings', 'orders'],
        rehydrate: true,
        restoreDates: false
    })(reducer);
}