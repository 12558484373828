import { Injectable } from '@angular/core';
import { EntityState, EntityStore, persistState, StoreConfig } from '@datorama/akita';
import { FlightOrder } from 'src/app/models/FlightOrder';


export interface OrdersState extends EntityState<FlightOrder, string>{}


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orders' , idKey: 'reference'})
export class OrdersStore extends EntityStore<OrdersState> {
    constructor() {
        super();
    }
}

export const ordersPersistStorage = persistState({
    include: ['orders'],
    key: 'ordersStore',
});