// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDBaU82iNWhahmrNQc81IbvD_PPd1GXFGo",
    authDomain: "flight-app-400920.firebaseapp.com",
    projectId: "flight-app-400920",
    storageBucket: "flight-app-400920.appspot.com",
    messagingSenderId: "15043204619",
    appId: "1:15043204619:web:f9febba5c027a066e1d90e",
    measurementId: "G-NZSBT4LYLN",
  },
  production: false,
  apiServer: 'https://server-dev.viajesdespejados.com/',
  assetServer: 'https://asia-travel-app.s3.eu-central-1.amazonaws.com',
  recaptchaKey: '6LeBfLMpAAAAAGl47c659f-OaB2T3URw9JvrP2EX',
  redsysHost: 'https://sis-t.redsys.es:25443',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
