import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionService } from './session.service';
import { AuthUser, LoggedAuthUser } from '../models/Authentication';
import { Store } from '@ngrx/store';
import { logoutAction } from '../stores/system/system.action';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authKey = 'authentication';

  private _logged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private authUser!: BehaviorSubject<AuthUser | null>;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  constructor(
    private sessionServ: SessionService,
    private store: Store
  ) {
    this.authUser = new BehaviorSubject<AuthUser | null>(null)
    this.getAuth().then(auth => {
      if (auth) {
        this.login(auth).then(() => {
          this.authUser.next(auth)
        });
      }
    })
  }

  async getToken(): Promise<string | undefined> {
    return (await this.getAuth())?.token
  }

  async getUserFullPhone(): Promise<string | undefined> {
    const auth = await this.getAuth()
    if (!auth) {
      return
    }
    return '+' + auth.phoneCountryCode + auth.phone
  }

  get logged(): Observable<boolean> {
    return this._logged.asObservable()
  }

  async logout() {
    await this.sessionServ.deleteValue(this.authKey)
    this._logged.next(false)
    this.authUser.next({})
    this.store.dispatch(logoutAction());
  }


  async login(auth: AuthUser) {
    await this.sessionServ.setValue(this.authKey, JSON.stringify(auth))
    this._logged.next(true)
    this.authUser.next(auth)
  }

  private async getAuth(): Promise<LoggedAuthUser | null> {
    const data: string | null = await this.sessionServ.getValue(this.authKey)

    if (!data) {
      this._logged.next(false)
      return null
    }

    const auth: LoggedAuthUser = JSON.parse(data)
    return auth
  }

  get user(): Observable<AuthUser | null> {
    return this.authUser.asObservable()
  }

}
