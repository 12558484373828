import { Injectable } from '@angular/core';
import ApiClientService from '../api-client.service';
import { Observable } from 'rxjs';
import { Airport } from 'src/app/models/FlightSearchResponse';
import { Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private apiClient: ApiClientService) { }

  getNearestAirport(coordinates: Position): Observable<Airport> {
    coordinates.coords.altitude;
    coordinates.coords.latitude;

    return this.apiClient.get('geo/airport/', {
      latitude: coordinates.coords.latitude,
      longitude: coordinates.coords.longitude,
    });
  }

  getAirportByCode(iataCode: string): Observable<Airport> {
    return this.apiClient.get(`airport/${iataCode}/`, {}, { skipToast: true });
  }
}
