import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClientModule,
  HttpClient,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

// Import date pipe related modules
import { DatePipe, registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/zh';
import localeEs from '@angular/common/locales/es';
import { DurationPipeModule } from './pipes/duration/duration.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { StoreModule } from '@ngrx/store';
import { locationReducer } from './stores/location/location.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LocationEffects } from './stores/location/location.effects';
import { flightSearchReducer } from './stores/flight-search/flight-search.reducer';
import { CsrfInterceptor } from './interceptors/CsrfInterceptor';
import { flightBookingReducer } from './stores/flight-booking/flight-booking.reducer';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import * as systemReducers from './stores/system/system.reducer';
import { settingsReducer } from './stores/settings/settings.reducer';
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { LocaleService } from './services/locale.service';

registerLocaleData(localeZh);
registerLocaleData(localeEs);

// Create a loader function that will load translations from the assets folder
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '/terms-of-use',
  privacyPolicyUrl: '/privacy-policy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({}),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),

    RouterModule.forRoot([]),
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'zh',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DurationPipeModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    // AngularFireMessagingModule,

    // Ngrx Store
    StoreModule.forRoot(
      {
        location: locationReducer,
        flightSearch: flightSearchReducer,
        flightBooking: flightBookingReducer,
        settings: settingsReducer,
      },
      {
        metaReducers: [systemReducers.localStorageSyncReducer, systemReducers.logout],
        runtimeChecks: {
          // strictStateImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([LocationEffects]),

    RecaptchaV3Module,

    // Akita store
    environment.production
      ? []
      : AkitaNgDevtools.forRoot({
        maxAge: 25,
      }),
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AngularFireAuth],
    // },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID, useFactory: (localeService: LocaleService) => localeService.getlanguage(),
      deps: [LocaleService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    DatePipe,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
